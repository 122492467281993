import React, { useState, useEffect } from "react";
import { FaEye, FaEyeSlash, FaTimes } from "react-icons/fa";

const LoginModal = ({ credential, credentials, onClose }) => {
  const [currentCredential, setCurrentCredential] = useState(credential);
  const [isClosing, setIsClosing] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    setCurrentCredential(credential);
  }, [credential]);

  const handleCredentialSwitch = (cred) => {
    setCurrentCredential(cred);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (currentCredential.project_id === 1201) {
      const loginUrl = "https://demo.payplatter.in/district_dashboard/logincontrol.php";
  
      // Login data
      const loginData = {
        logincontrol: "logindata",
        email: currentCredential.username,
        pswd: currentCredential.password,
      };
  
      try {
        // Send POST request to logincontrol.php
        const response = await fetch(loginUrl, {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: new URLSearchParams(loginData), // Convert data to URL-encoded format
        });
  
        if (!response.ok) {
          throw new Error("Login request failed");
        }
  
        // Get response text
        const responseText = await response.text();
  
        // Redirect based on response
        if (responseText === "DISTRICT") {
          window.open("https://demo.payplatter.in/district_dashboard/dm_dashboard/", "_blank");
        } else if (responseText === "SUBDIVISION") {
          window.open("https://demo.payplatter.in/district_dashboard/sd_dashboard/", "_blank");
        } else if (responseText === "BLOCK") {
          window.open("https://demo.payplatter.in/district_dashboard/block_dashboard/", "_blank");
        } else if (responseText === "DEPARTMENT") {
          window.open("https://demo.payplatter.in/district_dashboard/department_dashboard/", "_blank");
        } else {
          alert("Invalid credentials or unexpected response: " + responseText);
        }
      } catch (error) {
        console.error("Error during login:", error);
        alert("An error occurred while logging in. Please try again.");
      }
      return;
    }
  
    // Default behavior for other project IDs
    const form = document.createElement("form");
    form.method = currentCredential.login_method;
    form.action = currentCredential.url;
    form.target = "_blank";
  
    const formData = {
      [currentCredential.username_field]: currentCredential.username,
      [currentCredential.password_field]: currentCredential.password,
    };
  
    Object.entries(formData).forEach(([key, value]) => {
      const input = document.createElement("input");
      input.type = "hidden";
      input.name = key;
      input.value = value;
      form.appendChild(input);
    });
  
    document.body.appendChild(form);
    form.submit();
    document.body.removeChild(form);
  };
  
  
  

  const handleClose = () => {
    setIsClosing(true);
    setTimeout(onClose, 400);
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  // Filter credentials for the same project
  const filteredCredentials = credentials.filter(
    (cred) =>
      cred.project_id === currentCredential.project_id &&
      !cred.url.startsWith("https://test.payplatter.in/welcome?mid")
  );

  return (
    <>
      <div
        className={`fixed top-0 right-0 h-full w-1/2 bg-white shadow-lg p-6 rounded-l-lg transition-transform ${
          isClosing ? "translate-x-full" : "translate-x-0"
        }`}
        style={{ zIndex: 50 }}
      >
        <button className="text-gray-500 mb-10" onClick={handleClose}>
          <FaTimes
            className="text-[#224466] cursor-pointer hover:text-[#336699]"
            size={20}
            title="Close"
          />
        </button>

        <p className="ms-2 mb-2 text-[#224466]"><b>Note: </b>Please sign out if you have previously logged in to the same application with a different role.</p>

        <div className="mb-6 border rounded-xl border-[#7e9cba] p-4 bg-[#f0f6fb]">
          <h3 className="text-2xl font-bold mb-6">
            Sign In to {currentCredential?.project_name || "Unknown Project"}
          </h3>

          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label className="block text-gray-700">Username</label>
              <input
                type="text"
                value={currentCredential?.username || ""}
                readOnly
                className="w-full p-2 border rounded"
              />
            </div>
            <div className="mb-4 relative">
              <label className="block text-gray-700">Password</label>
              <div className="relative">
                <input
                  type={showPassword ? "text" : "password"}
                  value={currentCredential?.password || ""}
                  readOnly
                  className="w-full p-2 pr-10 border rounded"
                />
                <div
                  className="absolute top-1/2 right-3 transform -translate-y-1/2 cursor-pointer text-black"
                  onClick={togglePasswordVisibility}
                  title={showPassword ? "Hide Password" : "Show Password"}
                >
                  {showPassword ? <FaEyeSlash /> : <FaEye />}
                </div>
              </div>
            </div>

            <button
              type="submit"
              className="bg-[#224466] text-white px-4 py-2 rounded transition-all duration-300 hover:bg-[#336699] hover:shadow-md"
            >
              Login
            </button>
          </form>
        </div>

        <div className="mb-6 border rounded-xl border-[#7e9cba] p-4 bg-[#f0f6fb]">
          <h4 className="text-lg font-semibold mb-4 text-[#224466]">
            Select Role:
          </h4>
          <div className="flex flex-wrap gap-4">
            {filteredCredentials.map((cred) => (
              <div
                key={cred.id}
                onClick={() => handleCredentialSwitch(cred)}
                className={`cursor-pointer px-4 py-2 rounded-lg shadow-md transition-all duration-300 border ${
                  cred.id === currentCredential.id
                    ? "bg-[#336699] text-white border-[#336699]"
                    : "bg-[#F1FAFF] text-[#224466] border-[#4b6e91] hover:bg-[#D1E7FF] hover:border-[#336699]"
                }`}
                style={{
                  minWidth: "120px",
                  textAlign: "center",
                }}
              >
                {cred.category || "No Category"}
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginModal;
