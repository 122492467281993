import React, { useState, useEffect } from "react";
import { FaCopy, FaSignInAlt, FaEye, FaEyeSlash, FaInfoCircle, FaRegListAlt } from "react-icons/fa"; // Import icons
import { toast } from "react-toastify";
import iconMapping from "../utils/iconMapping"; // Import the icon mapping

const API_URL = process.env.REACT_APP_API_URL;

const CredentialCard = ({
  project_id,
  project_name,
  category,
  url,
  username,
  password,
  username_field,
  password_field,
  action,
  login_method,
  payload_format,
  notes,
  onLoginClick,
  id,
}) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [attachments, setAttachments] = useState([]);

  // Fetch project-level attachments dynamically
  useEffect(() => {
    const fetchAttachments = async () => {
      try {
        const response = await fetch(`${API_URL}/api/attachments/${project_id}`);
        if (!response.ok) {
          throw new Error("Failed to fetch attachments");
        }
        const data = await response.json();
        setAttachments(data);
      } catch (error) {
        console.error("Error fetching attachments:", error);
        toast.error(`Failed to load attachments for ${project_name}`, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
        });
      }
    };

    if (project_id) {
      fetchAttachments();
    }
  }, [project_id]);

  const copyToClipboard = (text, field) => {
    navigator.clipboard.writeText(text);
    toast.success(`${field} copied to clipboard!`, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  };

  const copyWithAttachments = () => {
    const formattedAttachments =
      attachments.length > 0
        ? attachments
            .map(
              (attachment, index) =>
                `Attachment${index + 1}: ${attachment.url}\nAttachment${index + 1} Type: ${attachment.attachment_type_name}`
            )
            .join("\n")
        : "No attachments";

    const content = isWelcomeURL
      ? `Project: ${project_name}\n\nAttachments for ${project_name}:\n${formattedAttachments}\n\nForm URL: ${url}`
      : `Project: ${project_name}\n\nAttachments for ${project_name}:\n${formattedAttachments}\n\nCategory: ${category}\nURL: ${url}\nUsername: ${username}\nPassword: ${password}\nNotes: ${notes}`;

    navigator.clipboard.writeText(content);
    toast.success(
      `${isWelcomeURL ? "Form details with attachments" : "Credentials with attachments"} copied for ${project_name}`,
      {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
      }
    );
  };

  const copyWithoutAttachments = () => {
    const content = isWelcomeURL
      ? `Project: ${project_name}\n\nForm URL: ${url}`
      : `Project: ${project_name}\n\nCategory: ${category}\nURL: ${url}\nUsername: ${username}\nPassword: ${password}\nNotes: ${notes}`;

    navigator.clipboard.writeText(content);
    toast.success(
      `${isWelcomeURL ? "Form details only" : "Credentials only"} copied for ${project_name}`,
      {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
      }
    );
  };

  const handleLogin = () => {
    if (!username_field || !password_field || !login_method || !payload_format) {
      toast.error("Login fields are not properly configured for this project", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
      });
      return;
    }

    const loginUrl = `${url}`;
    const loginData = {
      [username_field]: username,
      [password_field]: password,
    };

    if (login_method === "GET" && payload_format === "QUERY") {
      // Construct query parameters for GET requests
      const params = new URLSearchParams(loginData).toString();
      window.open(`${loginUrl}?${params}`, "_blank"); // Open in a new tab
    } else if (login_method === "POST") {
      if (payload_format === "JSON") {
        // Send JSON payload via fetch
        fetch(loginUrl, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(loginData),
        })
          .then((response) => {
            if (response.redirected) {
              window.location.href = response.url; // Redirect if server responds with a redirection URL
            } else {
              toast.success("Login request sent successfully!", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: true,
              });
            }
          })
          .catch((error) => {
            console.error("Error sending login request:", error);
            toast.error("Error sending login request", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: true,
            });
          });
      } else if (payload_format === "FORM") {
        // Emulate form submission for form-based POST requests
        const form = document.createElement("form");
        form.method = "POST";
        form.action = loginUrl;
        form.target = "_blank"; // Open in a new tab

        // Append form data
        Object.keys(loginData).forEach((key) => {
          const input = document.createElement("input");
          input.type = "hidden";
          input.name = key;
          input.value = loginData[key];
          form.appendChild(input);
        });

        document.body.appendChild(form);
        form.submit();
        document.body.removeChild(form); // Clean up

        toast.success("Login request sent successfully!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
        });
      } else {
        toast.error("Unsupported payload format for POST request", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
        });
      }
    } else {
      toast.error("Unsupported login method", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
      });
    }
  };

  const isWelcomeURL =
  url.startsWith("https://test.payplatter.in/welcome?mid") ||
  url === "http://103.151.107.57/social_justice/swadhar-scheme-application" ||
  url === "http://103.151.107.57/social_justice/foreign-scholarship";


    // Define dynamic details for specific IDs
    const dynamicDetails = {
      30: [
        { label: "Mobile No.", value: "888899999" },
        { label: "Roll No.", value: "222" },
      ],
      36: [
        { label: "Mobile No.", value: "9403231471" },
        { label: "Roll No.", value: "1234" },
      ],
      37: [
        { label: "Mobile No.", value: "8707761540" },
        { label: "Connection No.", value: "123" },
      ],
      38: [
        { label: "Flat No.", value: "A-111" },
        { label: "Flat No.", value: "A4-101" },
      ],
      39: [
        { label: "Flat No.", value: "NA" },
        { label: "Connection No.", value: "NA" },
      ],
      48: [
        { label: "Mobile No.", value: "7355649772" },
      ],
      49: [
        { label: "Mobile No.", value: "8411048701" },
        { label: "Shop No.", value: "1001" },
      ],
    };

  return (
    <div className="bg-white rounded-lg shadow-lg p-4 mb-4 relative flex items-start">
      <div className="flex-1">
        {/* Category Section */}
        <div className="grid grid-cols-12 gap-2 items-center mb-4">
          <FaRegListAlt className="text-[#336699] col-span-1" title="Category" />
          <span className="text-[#224466] col-span-11 truncate">
            <span className="font-semibold">
              {isWelcomeURL ? "Home Page Details:" : "Credentials For:"}{" "}
            </span>
            {category}
          </span>
        </div>

       {/* Buttons Section */}
<div className="flex flex-col space-y-1 mx-20">
  {isWelcomeURL || dynamicDetails[id] ? (
    <>
      <button
        onClick={() => window.open(url, "_blank")}
        className="bg-[#F1FAFF] text-[#336699] px-2 py-1 rounded-md hover:bg-[#D1E7FF] hover:text-[#224466] flex items-center justify-center text-xs font-medium transition duration-300 ease-in-out border border-[#D1E7FF]"
        title="Visit Homepage"
      >
        Visit Homepage
      </button>

      {/* Dynamic Details */}
      {dynamicDetails[id]?.map((detail, index) => (
        <button
          key={index}
          onClick={() => copyToClipboard(detail.value, detail.label)}
          className="bg-[#F1FAFF] text-[#336699] px-2 py-1 rounded-md hover:bg-[#D1E7FF] hover:text-[#224466] flex items-center justify-center text-xs px-auto font-medium transition duration-300 ease-in-out border border-[#D1E7FF]"
          title={`Copy ${detail.label}`}
        >
          <FaCopy className="mr-2 text-sm" />
          {detail.label}: {detail.value}
        </button>
      ))}
    </>
  ) : (
    <>
      <button
        onClick={() =>
          onLoginClick({
            project_id,
            project_name,
            category,
            url,
            username,
            password,
            username_field,
            password_field,
            action,
            login_method,
            payload_format,
            notes,
          })
        }
        className="bg-[#F1FAFF] text-[#336699] px-2 py-1 rounded-md hover:bg-[#D1E7FF] hover:text-[#224466] flex items-center justify-center text-xs font-medium transition duration-300 ease-in-out border border-[#D1E7FF]"
        title="Open Login Modal"
      >
        <FaSignInAlt className="mr-1 text-sm" /> Login
      </button>

      <button
        onClick={copyWithAttachments}
        className="bg-[#F1FAFF] text-[#336699] px-2 py-1 rounded-md hover:bg-[#D1E7FF] hover:text-[#224466] flex items-center justify-center text-xs font-medium transition duration-300 ease-in-out border border-[#D1E7FF]"
        title="Copy Creds And Attachments"
      >
        <FaCopy className="mr-1 text-sm" />
        Copy Creds And Attachments
      </button>

      <button
        onClick={copyWithoutAttachments}
        className="bg-[#F1FAFF] text-[#336699] px-2 py-1 rounded-md hover:bg-[#D1E7FF] hover:text-[#224466] flex items-center justify-center text-xs font-medium transition duration-300 ease-in-out border border-[#D1E7FF]"
        title="Copy Creds Only"
      >
        <FaCopy className="mr-1 text-sm" />
        Copy Creds Only
      </button>
    </>
  )}
</div>


      </div>
    </div>
  );
};

export default CredentialCard;
