import { FaSchool, FaSignInAlt, FaRegNewspaper,  FaDesktop,  FaVideo, FaFolder, FaMobileAlt,  FaLink ,FaFileAlt,FaChalkboard, FaUniversity, FaHome, FaPlaceOfWorship, FaHospital, FaCopy } from "react-icons/fa";
import { MdCorporateFare, MdPayment, MdPayments  } from "react-icons/md";
import { AiOutlineDashboard, AiOutlineShop } from "react-icons/ai";
import { GiBank, GiTicket, GiEcology  } from "react-icons/gi";
import { PiOfficeChairFill } from "react-icons/pi";
import { RiSecurePaymentLine } from "react-icons/ri";
import { IoQrCodeSharp } from "react-icons/io5";

import { GrConnect } from "react-icons/gr";
import { BsCollection } from "react-icons/bs";
import { RiCustomerService2Fill } from "react-icons/ri";
import { PiBuildingsDuotone } from "react-icons/pi";


const iconMapping = {
  FaSchool: <FaSchool />,
  FaUniversity: <FaUniversity />,
  IoQrCodeSharp:<IoQrCodeSharp/>,
  FaHome: <FaHome />,
  BsCollection:<BsCollection/>,
  GrConnect:<GrConnect/>,
  PiBuildingsDuotone: <PiBuildingsDuotone/>,
  RiCustomerService2Fill:<RiCustomerService2Fill/>,
  RiSecurePaymentLine: <RiSecurePaymentLine/>,
  GiEcology : <GiEcology />,
  FaPlaceOfWorship: <FaPlaceOfWorship />,
  PiOfficeChairFill: <PiOfficeChairFill />,
  FaHospital: <FaHospital />,
  MdCorporateFare: <MdCorporateFare />,
  MdPayment: <MdPayment />,
  MdPayments: <MdPayments />,
  AiOutlineDashboard: <AiOutlineDashboard />,
  AiOutlineShop: <AiOutlineShop />,
  GiBank: <GiBank />,
  GiTicket: <GiTicket />,
  FaFileAlt: <FaFileAlt />,
  FaChalkboard: <FaChalkboard />,
  FaLink: <FaLink />,
  FaVideo: <FaVideo />,
  FaFolder: <FaFolder />,
  FaMobileAlt: <FaMobileAlt />,
  FaCopy: <FaCopy />,
  FaDesktop: <FaDesktop />,
  FaRegNewspaper: <FaRegNewspaper />,
  FaSignInAlt: <FaSignInAlt />
  

};

export default iconMapping;
