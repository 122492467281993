import React, { useState, useEffect } from "react";
import SectionCard from "../components/SectionCard";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import iconMapping from "../utils/iconMapping"; // Import the icon mapping

const API_URL = process.env.REACT_APP_API_URL;

const HomePage = () => {
  const [sections, setSections] = useState([]);
  const [filteredSections, setFilteredSections] = useState([]);
  const [activeTab, setActiveTab] = useState("Use Case");
  const [credentials, setCredentials] = useState({});
  const [attachments, setAttachments] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();

  const [gibSections, setGibSections] = useState([]);
  const [tascSections, setTascSections] = useState([]);

 // Fetch all sections
 useEffect(() => {
  fetch(`${API_URL}/api/sections`)
  // fetch("http://localhost:8000/api/sections")
    .then((response) => {
      if (!response.ok) {
        throw new Error("Failed to fetch sections");
      }
      return response.json();
    })
    .then((data) => { 
      setSections(data);
      const useCaseSections = data.filter((section) => section.type === "Use Case");
      setFilteredSections(useCaseSections);
    setGibSections(useCaseSections.filter((section) => section.subtype === "GIB"));
        setTascSections(useCaseSections.filter((section) => section.subtype === "TASC"));
  })
    .catch((error) => {
      console.error("Error fetching sections:", error);
      toast.error("Failed to load sections", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
      });
    });
}, []);

// Handle tab switch
const handleTabSwitch = (type) => {
  setActiveTab(type);
  setFilteredSections(sections.filter((section) => section.type === type));
};

// Filter sections based on search term
const handleSearch = (e) => {
  const term = e.target.value.toLowerCase();
  setSearchTerm(term);

  if (activeTab === "Use Case") {
    setGibSections(
      sections.filter(
        (section) =>
          section.type === "Use Case" &&
          section.subtype === "GIB" &&
          section.title.toLowerCase().includes(term)
      )
    );

    setTascSections(
      sections.filter(
        (section) =>
          section.type === "Use Case" &&
          section.subtype === "TASC" &&
          section.title.toLowerCase().includes(term)
      )
    );
  } else {
    setFilteredSections(
      sections.filter(
        (section) =>
          section.type === "Section" &&
          section.title.toLowerCase().includes(term)
      )
    );
  }
};



  // Fetch credentials and attachments for all sections dynamically
  useEffect(() => {
    const fetchAllData = async () => {
      const fetchedCredentials = {};
      const fetchedAttachments = {};

      for (const section of sections) {
        try {
          // Fetch credentials for the current section
          const credentialsResponse = await fetch(
            `${API_URL}/api/credentials/${section.id}`
            // `http://localhost:8000/api/credentials/${section.id}`
          );
          const credentialsData = await credentialsResponse.json();
          fetchedCredentials[section.id] = credentialsData;

          // Fetch attachments for each project in the section's credentials
          for (const credential of credentialsData) {
            if (!fetchedAttachments[credential.project_id]) {
              try {
                const attachmentsResponse = await fetch(
                  `${API_URL}/api/attachments/${credential.project_id}`
                  // `http://localhost:8000/api/attachments/${credential.project_id}`
                );
                const attachmentsData = await attachmentsResponse.json();
                fetchedAttachments[credential.project_id] = attachmentsData;
              } catch (attachmentsError) {
                console.error(
                  `Error fetching attachments for project ${credential.project_id}:`,
                  attachmentsError
                );
                toast.error(
                  `Failed to load attachments for project ${credential.project_name}`,
                  {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: true,
                  }
                );
              }
            }
          }
        } catch (error) {
          console.error(
            `Error fetching credentials for section ${section.id}:`,
            error
          );
          toast.error(`Failed to load credentials for ${section.title}`, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
          });
        }
      }

      setCredentials(fetchedCredentials);
      setAttachments(fetchedAttachments);
    };

    if (sections.length > 0) {
      fetchAllData();
    }
  }, [sections]);


  // const filteredSections = sections.filter((section) =>
  //   section.title.toLowerCase().includes(searchTerm.toLowerCase())
  // );

  const handleSectionClick = (id, title) => {
    navigate(`/section/${id}`, { state: { sectionName: title } });
  };

  return (
    <div className="min-h-screen bg-[#F1FAFF] p-6">
     <ToastContainer />
    <div id="logo">
      <img
        src="https://dexpertsystems.in/assets/logo/web_icon.png" // Replace with actual image source
        alt="Logo"
        style={{ height: '50px' }}
      />
    </div>
      <header className="text-center mb-4">

        <h1 className="text-4xl font-bold text-[#336699]">Dexpert Systems Private Limited</h1>
        <p className="text-[#224466] mt-4">Exploxe By</p>
      </header>

      {/* Tabs */}
      <div className="mb-0 flex justify-center">
      <button
          onClick={() => handleTabSwitch("Use Case")}
          className={`px-4 py-2 me-1 rounded-t-md ${
            activeTab === "Use Case" ? "bg-[#336699] text-white" : "bg-gray-200 text-[#336699] hover:bg-gray-300"
          }`}
        >
          Use Cases
        </button>
        <button
          onClick={() => handleTabSwitch("Section")}
          className={`px-4 py-2 me-1 rounded-t-md ${
            activeTab === "Section" ? "bg-[#336699] text-white" : "bg-gray-200 text-[#336699] hover:bg-gray-300"
          }`}
        >
          Segments
        </button>
        
      </div>

      <div className="  my-0 p-3 border border-[#D1E7FF] rounded-b-md">
      <div className="mb-6">
        <input
          type="text"
          placeholder={activeTab === "Use Case" ? "Search for a Use Case..." : "Search for a Segment..."}
          className="w-full p-3 border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-[#336699]"
          value={searchTerm}
          onChange={handleSearch}
        />
      </div>




      <div>
  {activeTab === "Use Case" ? (
    <>
      {/* GIB Section */}
      <div className="mb-4 p-3 border border-[#D1E7FF] rounded-b-md">
        <h3 className="text-2xl mb-3 font-bold text-[#336699] border border-dotted border-[#cfe6fe] px-2 py-1 rounded-md  bg-[#ffffff]">GIB</h3>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
          {gibSections.map((section) => {
            const sectionCredentials = credentials[section.id] || []; // Credentials for the section
            const sectionAttachments = sectionCredentials.map((cred) => ({
              ...cred,
              attachments: attachments[cred.project_id] || [],
            }));

            return (
              <SectionCard
                key={section.id}
                id={section.id}
                title={section.title}
                icon={iconMapping[section.icon] || iconMapping.default}
                credentials={sectionAttachments} // Pass credentials
                onClick={() => handleSectionClick(section.id, section.title)}
              />
            );
          })}
        </div>
      </div>

      {/* TASC Section */}
      <div className=" p-3 border border-[#D1E7FF] rounded-b-md">
        <h3 className=" text-2xl mb-3 font-bold text-[#336699] border border-dotted border-[#cfe6fe] px-2 py-1 rounded-md  bg-[#ffffff]">TASC</h3>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
          {tascSections.map((section) => {
            const sectionCredentials = credentials[section.id] || []; // Credentials for the section
            const sectionAttachments = sectionCredentials.map((cred) => ({
              ...cred,
              attachments: attachments[cred.project_id] || [],
            }));

            return (
              <SectionCard
                key={section.id}
                id={section.id}
                title={section.title}
                icon={iconMapping[section.icon] || iconMapping.default}
                credentials={sectionAttachments} // Pass credentials
                onClick={() => handleSectionClick(section.id, section.title)}
              />
            );
          })}
        </div>
      </div>
    </>
  ) : (
    /* Segments Section */
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
      {filteredSections.map((section) => {
        const sectionCredentials = credentials[section.id] || []; // Credentials for the section
        const sectionAttachments = sectionCredentials.map((cred) => ({
          ...cred,
          attachments: attachments[cred.project_id] || [],
        }));

        return (
          <SectionCard
            key={section.id}
            id={section.id}
            title={section.title}
            icon={iconMapping[section.icon] || iconMapping.default}
            credentials={sectionAttachments} // Pass credentials
            onClick={() => handleSectionClick(section.id, section.title)}
          />
        );
      })}
    </div>
  )}
</div>

      </div>
    </div>
  );
};

export default HomePage;
