import React, { useState, useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CredentialCard from "../components/CredentialCard";
import { FaCopy, FaPaperclip, FaLink } from "react-icons/fa";
import iconMapping from "../utils/iconMapping"; 
import LoginModal from "../components/LoginModal"; // Import the modal

const API_URL = process.env.REACT_APP_API_URL;

const SectionPage = () => {
  const { id } = useParams(); // Get section ID from URL
  const location = useLocation(); // Get section name from navigation state
  const [sectionName, setSectionName] = useState(location.state?.sectionName || ""); // Updated logic for section name

  const [credentials, setCredentials] = useState([]); // Store fetched credentials
  const [projects, setProjects] = useState([]); // Store projects
  const [attachments, setAttachments] = useState({}); // Store attachments
  const [selectedProject, setSelectedProject] = useState(null);
  const [selectedCredential, setSelectedCredential] = useState(null); // For modal
  const [isModalOpen, setIsModalOpen] = useState(false); // Modal state

 // Fetch credentials for the section
 useEffect(() => {
  const fetchSectionData = async () => {
    try {
       // Fetch section details
       const sectionResponse = await fetch(`${API_URL}/api/sections`);
       if (!sectionResponse.ok) {
         throw new Error("Failed to fetch section details");
       }
       const sectionsData = await sectionResponse.json();
       const currentSection = sectionsData.find((section) => section.id === parseInt(id, 10));
       if (currentSection) {
         setSectionName(currentSection.title);
       }


      const credentialsResponse = await fetch(
        `${API_URL}/api/credentials/${id}`
        // `http://localhost:8000/api/credentials/${id}`
      );

      if (!credentialsResponse.ok ) {
        throw new Error("Failed to fetch section credentials");
      }

      const credentialsData = await credentialsResponse.json();

      // Filter out credentials with "placeholder" as category
      const filteredCredentials = credentialsData.filter(
        (cred) => cred.category.toLowerCase() !== "placeholder"
      );


      // Check if username_field and password_field exist
      credentialsData.forEach((cred) => {
        if (!cred.username_field || !cred.password_field) {
          console.warn(`Missing login fields for credential ID: ${cred.id}`);
        }
      });

       // Extract unique projects associated with this section
       const uniqueProjects = credentialsData.reduce((acc, cred) => {
        if (!acc.find((proj) => proj.id === cred.project_id)) {
          acc.push({ id: cred.project_id, name: cred.project_name });
        }
        return acc;
      }, []);
console.log("creds data: " ,credentialsData)
      setCredentials(filteredCredentials);
      setProjects(uniqueProjects);

      // Default to first project if it exists
if (uniqueProjects.length > 0) {
  setSelectedProject(uniqueProjects[0].id);
}

      // Fetch attachments for all unique projects
      const attachmentsData = {};
      for (const project of uniqueProjects) {
        const attachmentsResponse = await fetch(
          `${API_URL}/api/attachments/${project.id}`
          //  `http://localhost:8000/api/attachments/${project.id}`
        );
        const projectAttachments = await attachmentsResponse.json();
        attachmentsData[project.id] = projectAttachments;
      }

      setAttachments(attachmentsData);

    } catch (error) {
      console.error("Error fetching section data:", error);
      toast.error("Failed to load section data", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
      });
    }
  };


  fetchSectionData();
}, [id]);

  // Filter credentials based on selected project
  const filteredCredentials = credentials.filter((cred) => selectedProject ? cred.project_id === selectedProject : true
);

  const filteredAttachments = selectedProject ? attachments[selectedProject] || [] : [];

  // Copy credentials for the selected project
  const copyProjectCredentials = () => {
    if (!selectedProject) {
      toast.error("Please select a specific project to copy credentials!", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
      });
      return;
    }

  
    const project = projects.find((proj) => proj.id === selectedProject);
    const projectName = project?.name || "Unknown";
  
    // Get attachments for the selected project
    const projectAttachments = attachments[selectedProject] || [];
  
    // Format attachments
    const formattedAttachments =
      projectAttachments.length > 0
        ? projectAttachments
            .map(
              (attachment, index) =>
                `Attachment${index + 1}: ${attachment.url}\nAttachment${index + 1} Type: ${attachment.attachment_type_name}`
            )
            .join("\n")
        : "No attachments";
  
    // Format credentials
    const formattedCredentials = filteredCredentials
      .map(
        (cred, index) =>
          `--- Category${index + 1} ---\nCategory: ${cred.category}\nURL: ${cred.url}\nUsername: ${cred.username}\nPassword: ${cred.password}\nNotes: ${cred.notes}`
      )
      .join("\n\n");
  
      const clipboardContent = `Project: ${projectName}\n\nAttachments for ${projectName}:\n${formattedAttachments}\n\n${formattedCredentials}`;
  
    navigator.clipboard.writeText(clipboardContent);
  
    toast.success(`Credentials for ${projectName} copied to clipboard!`, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: true,
    });
  };
  

  // console.log("Credential Data:", credentials);


  return (
    <div className="min-h-screen bg-[#F1FAFF] p-6">
      <ToastContainer />
      <div id="logo">
      <img
  src="https://dexpertsystems.in/assets/logo/web_icon.png"
  alt="Logo"
  style={{ height: "50px" }}
  onClick={() => {
    if (window.history.length > 1) {
      window.history.back();
    } else {
      window.location.href = "https://resources.payplatter.in/";
    }
  }}
/>

      </div>
      <header className="mb-6 mt-6">
      <h2 className="text-3xl font-bold text-[#336699]">
          {sectionName ? `${sectionName} Section` : "Section"}
        </h2>
        <button
  onClick={() => {
    if (window.history.length > 1) {
      window.history.back();
    } else {
      window.location.href = "https://resources.payplatter.in/";
    }
  }}
  className="relative text-sm text-[#224466] group hover:text-[#004466] font-semibold transition-all duration-300 ease-in-out ml-4"
>
  <span className="absolute left-0 -translate-x-full group-hover:translate-x-0 transition-all duration-300 ease-in-out">
    ⇠
  </span>
  <span className="ml-3 group-hover:underline">Back</span>
</button>

      </header>

      {/* Tabs and Copy Button */}
      <div className="mb-0">
        <div className="flex items-center justify-between">
          <div className="flex space-x-1 overflow-x-auto">
            {projects.map((project) => (
              <button
                key={project.id}
                onClick={() => setSelectedProject(project.id)}
                className={`px-4 py-2 rounded-t-md ${
                  selectedProject === project.id
                    ? "bg-[#336699] text-white"
                    : "bg-gray-200 text-[#336699] hover:bg-gray-300"
                }`}
              >
                {project.name}
              </button>
            ))}
          </div>
          {selectedProject && (
            <button
              onClick={copyProjectCredentials}
              className="bg-[#ecf5ff] border border-[#85a6c7] text-[#336699] px-4 py-2 rounded-t-md  hover:bg-[#c6e2ff] flex items-center justify-center"
            >
              <FaCopy className="mr-2" /> <b>Copy Credentials</b>
            </button>
          )}
        </div>
      </div>
<div className="  my-0 p-3 border border-[#D1E7FF] rounded-b-md">
      {/* Attachments Section */}
      <div className="mb-6">
        <h3 className="text-2xl font-semibold text-[#336699] mb-4">Attachments</h3>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
          {filteredAttachments.length > 0 ? (
            filteredAttachments.map((attachment, index) => (
              <div
                key={index}
                className="bg-[#EAF4FF] p-4 rounded-lg shadow-sm border border-[#D1E7FF]"
              >
                <div className="flex items-center mb-2 text-[#336699]">
                {iconMapping[attachment.attachment_type_icon] || (
                  <FaPaperclip className="text-[#336699] mr-2" />
                  )}
                  
                  <span className="font-semibold text-[#224466] truncate">
                    &nbsp;{attachment.attachment_type_name}
                  </span>
                </div>
                <a
                  href={attachment.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-[#336699] hover:underline text-sm truncate block"
                >
                <button
        className="bg-[#F1FAFF] text-[#336699] px-2 py-1 rounded-md hover:bg-[#D1E7FF] hover:text-[#224466] flex items-center justify-center text-xs font-medium transition duration-300 ease-in-out border border-[#D1E7FF]"
        title="Link"
      >
                  <FaLink className="mr-1 text-sm"/>Open Attachment Link
                  </button>
                </a>
              </div>
            ))
          ) : (
            <p className="text-center text-[#224466] col-span-full">
              No attachments found for the selected project.
            </p>
          )}
        </div>
      </div>


      {/* Credential Cards */}
      <div className="mb-6">
      <h3 className="text-2xl font-semibold text-[#336699] mb-4">Credentials and Form Details</h3>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
        {filteredCredentials.length > 0 ? (
          filteredCredentials.map((cred) => (
            <CredentialCard key={cred.id} {...cred} 
              onLoginClick={(selectedCred) => {
            setSelectedCredential(selectedCred); // Set the clicked credential
            setIsModalOpen(true); // Open the modal
          }}

            />
          ))
        ) : (
          <p className="text-center text-[#224466] col-span-full">
            No credentials found for the selected project.
          </p>
        )}
      </div>
      </div>
      </div>
       {/* Login Modal */}
       {isModalOpen && (
        <LoginModal
  credential={selectedCredential}
  credentials={credentials} // Pass all credentials
  onClose={() => setIsModalOpen(false)}
/>

      )}
    </div>
  );
};

export default SectionPage;
